<template>
  <div>
    <div v-if="userLevel === 'org'"><IntelOrgDashboard /></div>

    <div v-else-if="userLevel === 'group'">Group</div>

    <div v-else-if="userLevel === 'advisor'"><IntelAdvisorDashboard /></div>
  </div>
</template>

<script>
import IntelAdvisorDashboard from "./IntelAdvisorDashboard.vue";
import IntelOrgDashboard from "./IntelOrgDashboard.vue";

export default {
  components: {
    IntelAdvisorDashboard,
    IntelOrgDashboard,
  },
  data() {
    return {
      userLevel: null,
    };
  },
  created() {
    const userLevel = localStorage.getItem("intelLevel");
    if (userLevel) {
      this.userLevel = userLevel;
    } else {
      this.userLevel = "org";
    }
  },
};
</script>

<style lang="scss" scoped></style>
