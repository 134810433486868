<template>
  <div class="mt-4">
    <v-row class="mb-4">
      <v-col class="col-12 col-md-9">
        <v-card outlined flat>
          <div class="mb-4 px-6 pt-4 pb-0 d-flex">
            <p class="font-weight-bold mb-0">Group Productivity</p>
          </div>
          <v-card-text><ActivityLineChart /> </v-card-text
        ></v-card>
      </v-col>

      <v-col class="col-12 col-md-3">
        <v-card outlined flat>
          <div class="mb-4 px-6 pt-4 pb-0 d-flex">
            <p class="font-weight-bold mb-0">Most Active</p>
          </div>
          <v-card-text
            ><v-list>
              <v-list-item> one</v-list-item>
              <v-list-item> one</v-list-item>
            </v-list></v-card-text
          ></v-card
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col class="col-12 col-sm-4 col-md-2">
        <v-card outlined flat>
          <div class="mb-4 px-6 pt-4 pb-0 d-flex">
            <p class="font-weight-bold mb-0">Sales Ratio</p>
          </div>
          <v-card-text
            ><GenericPieChart
              :chartData="{
                labels: ['Open', 'Closed', 'In-Progress'],
                datasets: [{ data: [42, 48, 10] }],
              }"
            /> </v-card-text
        ></v-card>
        <v-card outlined flat>
          <div class="mb-4 px-6 pt-4 pb-0 d-flex">
            <p class="font-weight-bold mb-0">Product Ratio</p>
          </div>
          <v-card-text
            ><GenericPieChart
              :chartData="{
                labels: ['Open', 'Closed', 'In-Progress'],
                datasets: [{ data: [15, 50, 35] }],
              }"
            /> </v-card-text
        ></v-card>
        <v-card outlined flat class="pa-4">
          <v-card-text
            ><v-btn color="primary"
              >View All <v-icon>mdi-arrow-right</v-icon>
            </v-btn></v-card-text
          ></v-card
        ></v-col
      >

      <v-col class="col-12 col-sm-8 col-md-10">
        <v-card outlined flat>
          <div class="mb-4 px-6 pt-4 pb-0 d-flex">
            <p class="font-weight-bold mb-0">Group Distribution</p>
          </div>
          <v-card-text> <GeoDistributionMap /></v-card-text></v-card
      ></v-col>
    </v-row>
  </div>
</template>

<script>
import ActivityLineChart from "../components/intel/ActivityLineChart";
import GenericPieChart from "../components/intel/GenericPieChart";
import GeoDistributionMap from "@/components/intel/GeoDistributionMap";

export default {
  components: {
    ActivityLineChart,
    GenericPieChart,
    GeoDistributionMap,
  },
};
</script>

<style lang="scss" scoped></style>
